import React, { useCallback, useEffect } from "react"
import { NavLink, Redirect, generatePath, useHistory, useLocation } from "react-router-dom";

import useConfig from 'app/hooks/useConfig';
import { useSelector } from "react-redux";
import { Modal, ModalTypical, Button } from "app/components";
import { useModal } from "app/hooks";
import { useTranslation } from "react-i18next";
import useLogout from 'app/hooks/useLogout';
import { RefundModalContent } from "../account/Tickets/styles";


const Sessions = ({ requestSesseionsTickets }) => {
  const [t, i18n] = useTranslation();
  const { routes } = useConfig();
  const [isModal, showSessionModal, hideModal] = useModal('sessionModal');
  const [isShowValidUser, showValidUserModal, hideValidUserModal] = useModal('attention');
  const isLoggedIn = localStorage.getItem("refreshToken") !== null ? localStorage.getItem("refreshToken") : ""
  const sessionPassData = useSelector(state => state?.sessionsTickets?.sessionsPassData);
  const userData = useSelector(state => state?.user?.user);
  const userLoggedInEmail = userData?.email;
  const logout = useLogout();
  const location = useLocation();
  const cinemaId = location?.pathname?.split('/')[2];
  const promotionId = location?.pathname?.split('/')[4];
  const emailId = location?.search?.split('=')[1];
  const history = useHistory();

  const onAccountCreated = useCallback(() => {
    hideModal();
    history.push(routes.movies.pattern);
  }, []);
  const handleRefund = () => {
    hideValidUserModal();
    logout();

    history.push({
      pathname: generatePath(routes.login.pattern),
      state: {email: emailId, promotionId, cinemaId}
    })

  };
  const handleHomePage = () => {
    hideValidUserModal();
    history.push(routes.movies.pattern);
  };

  useEffect(() => {
    if (isLoggedIn) {
      requestSesseionsTickets({ permotionId: promotionId, cinemaId })
    }
  }, [])

  useEffect(() => {
    if (sessionPassData) {
      const movieCinemaId = sessionPassData?.sessions?.[0]?.id;
      // if (movieCinemaId && emailId === userLoggedInEmail) {

      if (movieCinemaId && emailId === userLoggedInEmail) {
        const path = generatePath(routes.stepSeats.pattern, { movieCinemaId, isBonusFromSessionPassData: false });

        history.push({
          pathname: path,
          state: { isBonusFromSessionPassData: false, email: emailId, promotionId, cinemaId }
        });
      }
      else if (movieCinemaId && emailId !== userLoggedInEmail) {
        showValidUserModal();
      }
      else if (sessionPassData?.sessions) {
        showSessionModal();
      }
    }
    else {
      showSessionModal();
    }
  }, [sessionPassData, userData])
  if (!isLoggedIn) {
    return <Redirect to={{
      pathname: routes.login.pattern,
      state: { email: emailId, promotionId, cinemaId }
    }} />;
  }



  return (
    <>
      <h1> Season Pass</h1>
      <Modal
        isOpen={isModal}
        close={hideModal}
        // title={t("modals.account_created.header")}
        title="Season Pass Session Modal"
      >
        <ModalTypical
          withIcon={false}
          // title={t("modals.account_created.title")}
          message={[
            "No Opera Sessions are scheduled for this Cinema"
            // t("modals.account_created.message_1"),
          ]}
          btnText={t("common.ok")}
          click={onAccountCreated}
        />
      </Modal>
      <Modal
        isOpen={isShowValidUser}
        title={t('purchase.modals.invalid_user.header')}
        close={handleHomePage}
        style={{ pointerEvents: 'none' }}
      >
        <RefundModalContent>
          <div className="refund_modal__modal_message">
            <h3>{t('purchase.modals.invalid_user.label')}</h3>
            <h3>{t('purchase.modals.invalid_user.info')}</h3>
          </div>
          <div className="seasonPass__modal_btns">
            <Button isDefault onClick={handleHomePage} style={{ textAlign: 'center', marginRight: '10px' }}>
              {t('common.cancel')}
            </Button>
            <Button isPrimary onClick={handleRefund}>
              {t('common.logout')}
            </Button>
          </div>
        </RefundModalContent>
      </Modal>
    </>
  )
};

export default Sessions;