import styled from 'styled-components';
import { ShadowBoxDark } from 'app/components';
import { textTransform } from 'utils';

export const BlockTickets = styled(ShadowBoxDark).attrs({
  className: 'b-tickets'
})`
  padding: 0;

  .b-tickets__refund_btn, .b-tickets__pickup_btn{
    margin-right: 33px;
    width: 200px;
  }
  .b-tickets__refund__concessions_btn{
    margin-left: 33px;
    width: 200px;
  }
  .b-cinema_title {
    color:red;
    font-size: 1.8rem;
    padding: 10px 8px 10px 14px;
    border-bottom: 1px solid #323232;
    text-transform: uppercase;
  }
`;

export const BlockTicketsCinemas = styled.div.attrs({
  className: 'b-tickets__cinemas'
})`
  padding: ${({ theme }) => theme.indent.m};
  border-bottom: 1px solid ${({ theme }) => theme.color.borderDark};

  .react-dropdown-select {
    max-width: 288px;
  }


  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    padding: ${({ theme }) => `${theme.indent.m} ${theme.indent.m} ${theme.indent.s}`}
    border-bottom-color: transparent;
    
    .react-dropdown-select {
      max-width: none;
    }
  }
`;

export const BookedTicketsList = styled.ul.attrs({
  className: 'l-booked-tickets'
})``;

export const BookedTicketsItem = styled.li.attrs({
  className: 'l-booked-tickets__item'
})`
  width: 100%;
  padding: 10px ${({ theme }) => theme.indent.s};

  & + & {
    border-top: 1px solid ${({ theme }) => theme.color.borderDark};
  }

  .l-booked-tickets__item-inner {
    display: flex;
    align-items: center;
    padding: ${({ theme }) => `${theme.indent.s} ${theme.indent.s}`};
    border-radius: 8px;
    position: relative;
    transition: background-color 0.2s ease-out;
    cursor: pointer;
  }

  .l-booked-tickets__reserved-icon-wrapper {
    position: absolute;
    top: ${({ theme }) => theme.indent.s};
    left: ${({ theme }) => theme.indent.s};
    z-index: 1;
    width: 19px;
    height: 24px;
  }

  .svg-bookmark {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .svg-clock {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    margin: 0 auto;
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
  }

  .b-image-with-loader {
    flex-shrink: 0;
    width: 60px;
  }

  &:hover {
    .l-booked-tickets__item-inner {
      background-color: ${({ theme }) => theme.color.borderDark};
    }
  }

  .l-booked-tickets__info {
    padding-left: ${({ theme }) => theme.indent.m};
  }

  .l-booked-tickets__info-title {
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: ${textTransform};
    color: ${({ theme }) => theme.color.textPrimary};
  }

  .l-booked-tickets__info-date {
    margin-top: 5px;
  }

  .l-booked-tickets__info-amount {
    margin-top: 5px;
    color: ${({ theme }) => theme.color.labelColor};
  }
`;

export const RefundModalContent = styled.div.attrs({
  className: 'refund_modal'
})`
  text-align: ${props => (props.isConcessions ? "center" : "left")};
  padding-left: 8px;

  .refund_modal__modal_message {
    height: 72px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      font-weight: 390;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .refund_modal__modal_btns {
    text-align: right;
    margin-top: 48px;
    button {
      &:first-child {
        margin-right: 18px;
      }
    }
  }
  .seasonPass__modal_btns {
    text-align:  ${props => (props.isConcessions ? "center" : "right")};
    margin-top: 48px;
    button {
      &:first-child {
        margin-right: 18px;
      }
    }
  }
  @media screen and (max-width: 410px) {
    .refund_modal__modal_btns {
      text-align: center;
      margin-top: ${props => (props.language === 'de' ? '90px' : '48px')};
      button {
        &:first-child {
          margin-right: 18px;
        }
      }
    }
    .seasonPass__modal_btns {
      text-align: center;
      margin-top: ${props => (props.language === 'de' ? '90px' : '48px')};
      button {
        &:first-child {
          margin-right: 18px;
        }
      }
    }
  }
`;
